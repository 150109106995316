export const thumbnailEventEntry = {
  fields: [
    'date_time_start',
    'date_time_end',
    'permanent_event'
  ],
  populate: {
    ...queryFragmentCollectionTypeDefault,
    cover_image: true,
    event_categories: {
      populate: {
        titles: true,
      }
    },
    // no city_entry
    event_locations: {
      populate: {
        address: true,
        cluster_entry: {
          populate: {
            ...queryFragmentCollectionTypeDefault,
          }
        }
      }
    },
    participant_entries: {
      populate: {
        ...queryFragmentCollectionTypeDefault,
      }
    },
    venue_entry: {
      populate: {
        ...queryFragmentCollectionTypeDefault,
        address: true,
        city_entry: {
          populate: {
            ...queryFragmentCollectionTypeDefault,
            cluster_entry: {
              populate: {
                ...queryFragmentCollectionTypeDefault,
              }
            }
          }
        }
      }
    },
    focus_week_entry: {
      populate: {
        address: true,
        city_entry: {
          populate: {
            cluster_entry: {
              populate: {
                ...queryFragmentCollectionTypeDefault,
              }
            }
          }
        }
      }
    }
  }
}
